import { createSSRApp, createApp } from "vue";
import { createPinia } from "pinia";
import router from "./router";
import { createHead } from "@vueuse/head";
import VueGtag from "vue-gtag-next";

import i18n from "./services/i18n";
import Flicking from "@egjs/vue3-flicking/dist/flicking.esm";
import "@egjs/vue3-flicking/dist/flicking.css";
/* Animation library */

import "./assets/js/bootstrap.min.js";
import "./assets/css/main.css";

import "animate.css";

import App from "./App.vue";

// Create a global head instance

export function createNewApp(ssr = import.meta.env.SSR) {
  const app = ssr ? createSSRApp(App) : createApp(App);
  app.use(createPinia()).use(router).use(createHead()).use(i18n);
  if (import.meta.env.VITE_ENVIRONMENT === "build") {
    app.use(VueGtag, {
      property: {
        //id: "UA-45066167-1",
        id: "GTM-M55TJQVS",
        // id: "G-TVD5MLZ1DW",
      },
      config: {
        id: "",
        params: {
          anonymize_ip: true,
        },
      },
    });
  }
  if (!ssr) {
    app.mount("#app");
  }

  app.component("Flicking", Flicking);
  return { app, router };
}

createNewApp();
