<template>
  <nav id="sideNavigation" :class="{ 'd-none d-lg-block': !mobile }">
    <ul class="list-unstyled d-flex d-lg-block m-0 saka_grey-no-hover">
      <li class="order-5 ms-auto">
        <a
          class="saka_blue d-flex sideLink p-2"
          href="https://pulse.sachsenkabel.de"
          target="_blank"
          :title="t('buttons.title.pulse')"
        >
          <pulse-cart-icon />
        </a>
      </li>
      <li>
        <a
          class="saka_grey saka_text_default text-center align-items-center justify-content-center d-flex sideLink p-2"
          target="_blank"
          :title="t('search.input.title')"
          @click.prevent="toggleShowSearch"
          href="#"
        >
          <bs-icon icon="search" />
        </a>
      </li>
      <li>
        <div class="dropdown" v-on-click-outside="hideLangDropDown">
          <a
            @click.prevent="langDropDown = !langDropDown"
            class="saka_grey language_switch saka_text_default d-flex flex-column sideLink p-2 pt-3"
            target="_blank"
            href="#"
            :title="t('buttons.title.change-language')"
          >
            {{ getLanguageFromString(preferencesStore.language) }}
            <bs-icon icon="chevron-down" :size="10" class="d-flex mx-auto" />
          </a>
          <div class="dropdown-content" v-show="langDropDown">
            <a
              v-for="(lang, index) in preferencesStore.givenLanguages"
              :key="index"
              @click.prevent="updateLanguage(lang.code)"
              class="align-items-center justify-content-center d-flex saka_grey"
              href="#"
            >
              {{ getLanguageFromString(lang.code) }}
            </a>
          </div>
        </div>
      </li>
      <li>
        <a
          class="saka_grey saka_text_default text-center d-flex sideLink p-2"
          target="_blank"
          href="#"
          :title="t('buttons.title.phone-contact')"
          @click.prevent="startCall"
        >
          <bs-icon icon="telephone" />
        </a>
      </li>
      <li>
        <div class="dropdown flex" v-on-click-outside="hideSocialDropDown">
          <a
            @click.prevent="socialDropDown = !socialDropDown"
            class="saka_grey saka_text_default text-center d-flex sideLink p-2"
            target="_blank"
            href="#"
            :title="t('buttons.title.social-media')"
          >
            <bs-icon icon="hash" />
          </a>
          <div class="dropdown-content social-dropdown" v-show="socialDropDown">
            <a
              class="saka_grey align-items-center justify-content-center d-flex"
              @click="socialDropDown = !socialDropDown"
              href="https://www.facebook.com/sachsenkabel"
              target="_blank"
            >
              <social-icon icon="facebook" color="currentColor" :size="30" />
            </a>
            <a
              class="saka_grey align-items-center justify-content-center d-flex"
              @click="socialDropDown = !socialDropDown"
              href="https://www.xing.com/companies/lwl-sachsenkabelgmbh"
              target="_blank"
            >
              <social-icon icon="xing" color="currentColor" :size="30" />
            </a>
            <a
              class="saka_grey align-items-center justify-content-center d-flex"
              @click="socialDropDown = !socialDropDown"
              href="https://de.linkedin.com/company/lwl-sachsenkabel-gmbh"
              target="_blank"
            >
              <social-icon icon="linkedin" color="currentColor" :size="30" />
            </a>
            <a
              class="saka_grey align-items-center justify-content-center d-flex"
              @click="socialDropDown = !socialDropDown"
              href="https://www.youtube.com/channel/UCxyJo39DlYqKOXfKpRLz6UQ"
              target="_blank"
              title="Youtube"
            >
              <social-icon icon="youtube" color="currentColor" :size="30" />
            </a>
          </div>
        </div>
      </li>
      <li>
        <router-link
          :to="linkContact"
          class="saka_grey saka_text_default text-center d-flex sideLink p-2"
          :title="t('buttons.title.contact-us')"
        >
          <bs-icon icon="at" color="currentColor" :size="20" />
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { ref, computed } from "vue";
import PulseCartIcon from "./icons/pulse-cart.vue";
import BsIcon from "./icons/bs-icon.vue";
import socialIcon from "./icons/social-icon.vue";
import { toggleShowSearch, getLanguageFromString } from "../services/helpers";
import { vOnClickOutside } from "@vueuse/components";
import { useI18n } from "vue-i18n";
import { usePageStore, useMenuStore, usePreferencesStore } from "../stores";

defineProps({
  mobile: {
    type: Boolean,
    default: false,
  },
});

const { t, locale } = useI18n({ useScope: "global" });

const pageStore = usePageStore();
const menuStore = useMenuStore();

const langDropDown = ref(false);
const socialDropDown = ref(false);

const preferencesStore = usePreferencesStore();

const linkContact = computed(() =>
  preferencesStore.language === "de-DE" ? "/kontakt" : "/en/contact"
);

const hideLangDropDown = () => {
  langDropDown.value = false;
};
const hideSocialDropDown = () => {
  socialDropDown.value = false;
};

const pageID = computed(() => pageStore.page.id);

const updateLanguage = (lang) => {
  preferencesStore.updateLanguage(lang).then(() => {
    const currentSlug = menuStore.getSlugById(pageID.value);
    //setzen der locale für i18n
    locale.value = lang;
    //setzen der neuen URL nach Sprache
    history.pushState({}, "", currentSlug);
  });

  hideLangDropDown();
};

const startCall = () => {
  window.open("tel:+49372139880");
};
</script>

<style scoped>
a {
  cursor: pointer;
}

#sideNavigation {
  position: fixed;
  z-index: 1799;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
}

.dropdown {
  position: relative;
  display: flex;
}

.dropdown-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  bottom: 100%;
  font-size: 0.7rem;
  background: rgba(255, 255, 255, 0.5);
}

.dropdown-content a {
  color: #ffff;
  aspect-ratio: 1;
  width: 3rem;
  text-decoration: none;
  margin-top: 1px;
}

@media (min-width: 992px) {
  #sideNavigation {
    position: fixed;
    z-index: 1799;
    right: 0;
    top: 0;
    left: auto;
    bottom: auto;
  }

  .dropdown-content {
    position: absolute;
    display: flex;
    flex-direction: row;
    z-index: 1;
    font-size: 0.7rem;
    right: 100%;
    bottom: 0;
  }

  .dropdown-content a {
    color: var(--saka_dark);
    aspect-ratio: 1;
    width: 3rem;
    text-decoration: none;
    margin-right: 1px;
  }

  .saka_blue {
    background: var(--saka_blue);
  }
}

.saka_blue {
  background: var(--saka_green);
}
.saka_blue:hover {
  background: var(--saka_orange);
  color: var(--saka_dark);
}

.saka_grey {
  background: var(--saka_orange);
  color: var(--saka_dark);
}

.saka_grey:hover {
  background: var(--saka_grey_bg);
  color: var(--saka_white);
}

.saka_grey-no-hover {
  background: var(--saka_grey_bg);
}

.sideLink {
  aspect-ratio: 1;
  width: 3rem;
  text-decoration: none;
  align-items: center;
  justify-content: center;
}

.language_switch {
  font-size: 0.7rem;
  text-align: center;
  line-height: 1;
}

.dropdown-content a:hover {
  background-color: var(--saka_grey_bg);
}
</style>
