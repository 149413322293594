import { defineStore } from "pinia";
import { directus } from "../services/directus";
import { readItems } from "@directus/sdk";

export const useExamplePointsStore = defineStore("examplePoints", {
  state: () => ({
    example_points: [],
  }),
  getters: {
    getExamplePointByKey: (state) => {
      return (key) => state.example_points.find((item) => item.id === key);
    },
  },
  actions: {
    async updateExamplePoints() {
      const resp = await directus.request(
        readItems("example_solutions_points", {
          fields: ["*.*"],
        })
      );
      this.example_points = resp;
    },
  },
});
