<template>
  <section class="search d-flex align-items-start justify-content-center">
    <a
      class="position-absolute top-0 end-0 m-3 text-white cursor-pointer"
      href="#"
      :title="t('search.close')"
    >
      <bs-icon icon="x-lg" :size="32" />
    </a>
    <div class="container px-lg-5">
      <h1 class="px-lg-5">{{ t("search.input.title") }}</h1>
      <div
        v-on-click-outside="toggleShowSearch"
        class="input-group mb-3 input-group-lg px-lg-5"
      >
        <span class="input-group-text bg-white" id="basic-addon1"
          ><bs-icon icon="search" :size="20" color="inherit"
        /></span>
        <input
          type="text"
          ref="searchInput"
          class="form-control border-start-0"
          placeholder="Search..."
          aria-label="Username"
          aria-describedby="basic-addon1"
          v-model="searchValue"
          @change="callSearchPage"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import { toggleShowSearch } from "../../services/helpers";
import { onMounted, ref } from "vue";
import BsIcon from "../icons/bs-icon.vue";
import { vOnClickOutside } from "@vueuse/components";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

const { t, locale } = useI18n({ useScope: "global" });

const searchValue = ref();
const router = useRouter();

const callSearchPage = () => {
  toggleShowSearch();
  const name = "de" === locale ? "SEARCH" : "ENGLISH SEARCH";
  router.push({ name: name, query: { s: searchValue.value } });
};

onMounted(() => {
  searchInput.value.focus();
});
</script>

<style scoped>
.search {
  background: rgba(var(--saka_blue_rgb), 90%);
  position: fixed;
  padding-top: 8%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1801;
}

.input-group-text {
  color: var(--saka_blue);
  border-color: #fff;
}

.form-control {
  border-left: 1px solid var(--saka_blue) !important;
  border-color: #fff;
}
.form-control:focus {
  border-color: #ced4da;
  outline: 0;
  box-shadow: none;
}

.animate__delay-500ms {
  animation-delay: 500ms;
}

h1 {
  color: rgba(255, 255, 255, 0.8);
}
</style>
